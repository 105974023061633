import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import image from "../images/logo.png";

export default function Navbar() {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const burgerMenuRef = React.useRef<HTMLButtonElement>(null);
  const navOptions = [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Services",
      path: "/services",
    },
    {
      label: "About",
      path: "/about",
    },
    {
      label: "Portfolio",
      path: "/portfolio",
    },
    {
      label: "Contact",
      path: "/contact",
    },
  ];

  return (
    <Flex
      minWidth="max-content"
      alignItems="center"
      p="2"
      bgColor="secondary.50"
      left={0}
      right={0}
      zIndex="1"
    >
      <Image w="150px" src={image} />
      <Spacer />
      <ButtonGroup
        gap="2"
        display={{
          xs: "none",
          md: "block",
        }}
      >
        {navOptions.map(({ label, path }, i) => (
          <Button
            as={NavLink}
            to={path}
            key={i}
            variant="ghost"
            colorScheme={pathname !== path ? "primary" : "secondary"}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
      <Spacer />
      <Button
        colorScheme="secondary"
        variant="outline"
        display={{
          xs: "none",
          md: "block",
        }}
      >
        Start Project
      </Button>
      <Fragment>
        <IconButton
          onClick={onOpen}
          ref={burgerMenuRef}
          colorScheme="secondary"
          aria-label="burger-menu"
          icon={<CgMenuGridR />}
          display={{
            xs: "flex",
            md: "none",
          }}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={burgerMenuRef}
          size="full"
          isFullHeight
          blockScrollOnMount={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader as={HStack} justify="space-between" p="8px">
              <Image w="150px" src={image} />
              <IconButton
                onClick={onClose}
                colorScheme="secondary"
                aria-label="burger-close-menu"
                icon={<IoMdClose />}
              />
            </DrawerHeader>

            <DrawerBody>
              <Input placeholder="Type here..." />
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Fragment>
    </Flex>
  );
}
