import {Center} from "@chakra-ui/react";
import WhyHawklix from "../home/sections/why-hawklix";

export default function About() {
  return (
    <Center>
      <WhyHawklix />
    </Center>
  );
}
