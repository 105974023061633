import { AbsoluteCenter, Box, Button, HStack, Text } from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { AppContext } from "../../hooks/contexts";
import Mobile from "./../../images/Icon-metro-mobile.jpg";
import mongo from "./../../images/mongologo.jpg";
import Nodejs from "./../../images/nodejs.png";
import Pen from "./../../images/pen.jpg";
import reactiIcon from "./../../images/reactlogo.jpg";
import wordpressIcon from "./../../images/wordpress.jpg";
import BannerItem from "./components/banner-item";
import Clients from "./sections/clients";
import OurExperties from "./sections/expertise";
import GetInTouch from "./sections/get-in-touch";
import Portfolio from "./sections/portfolio";
import WhyHawklix from "./sections/why-hawklix";
export default function Home() {
  const navbarContext = useContext(AppContext);
  const ContainerRef = useRef<HTMLDivElement>(null);
  const itemOneRef = useRef<HTMLDivElement>(null);
  const itemTwoRef = useRef<HTMLDivElement>(null);
  const itemThreeRef = useRef<HTMLDivElement>(null);
  const itemFourRef = useRef<HTMLDivElement>(null);
  const itemFiveRef = useRef<HTMLDivElement>(null);
  const MainRef = useRef<HTMLDivElement>(null);

  return (
    <Box>
      <Box
        h={`calc(100vh - ${navbarContext.Navbarheight}px)`}
        bg="secondary.50"
        position="relative"
        ref={ContainerRef}
        overflow="hidden"
      >
        {/* <LinesContainer /> */}
        <BannerItem
          title="Node js"
          description="Nodejs is a backend language"
          image={Nodejs}
          ref={itemOneRef}
          top={90}
          left={80}
        />
        <BannerItem
          title="React Native"
          description="React Native is a Frontend language"
          image={Mobile}
          ref={itemTwoRef}
          top={73}
          left={33}
        />
        <BannerItem
          title="Node js"
          description="Nodejs is a backend language"
          image={Pen}
          ref={itemThreeRef}
          top={25}
          left={25}
        />
        <BannerItem
          ref={itemFourRef}
          image={mongo}
          top={30}
          left={80}
          title="Mongo DB"
          description="Mongo DB is a database"
        />
        <BannerItem
          ref={itemFiveRef}
          image={reactiIcon}
          top={90}
          left={22}
          title="Node js"
          description="Nodejs is a backend language"
        />
        <BannerItem
          ref={itemFiveRef}
          image={wordpressIcon}
          top={65}
          left={90}
          title="Wordpress"
          description="Wordpress is a CMS"
        />

        <AbsoluteCenter>
          <HStack justify="center" mb="2">
            <Button
              colorScheme="gray"
              _hover={{
                // transform: "scale(1.1)",
                border: "2px solid orange",
              }}
              onClick={async () => {}}
            >
              Contact Us
            </Button>
            <Button
              colorScheme="gray"
              _hover={{
                // transform: "scale(1.3)",
                border: "2px solid orange",
              }}
              onClick={() => {}}
            >
              Our Services
            </Button>
          </HStack>
          <Box
            borderWidth="thick"
            borderColor="gray.200"
            borderRadius="80px"
            padding={{
              md: "50px 80px",
              sm: "30px 30px",
              xs: "10px 20px",
            }}
            ref={MainRef}
          >
            <HStack>
              <Text
                fontSize={{
                  xl: "3xl",
                  lg: "xl",
                  md: "lg",
                  sm: "md",
                  xs: "md",
                }}
                fontWeight="semibold"
                cursor="default"
              >
                Do You Need Website ?
              </Text>
            </HStack>
          </Box>
        </AbsoluteCenter>
      </Box>
      <WhyHawklix />
      <OurExperties />
      <Portfolio />
      <Clients />
      <GetInTouch />
    </Box>
  );
}
