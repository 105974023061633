import {TriangleUpIcon} from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import {motion, useAnimationControls} from "framer-motion";
import {useState} from "react";
import naplozz from "./../../../images/naplozz.png";
import ScrollLeftSVG from "./../../../images/scroll-left.png";
import ScrollRightSVG from "./../../../images/scroll-right.svg";
export default function Portfolio() {
  const portfolioItems = [
    {
      label: "Smart Quality Management",
      key: "school-management-system",
      images: [naplozz],
    },
    {
      label: "Food Delivery Application",
      key: "food-delivery-application",
      images: [naplozz, naplozz],
    },
    {
      label: "Hoogle Maps",
      key: "hoogle-maps",
      images: [naplozz],
    },
    {
      label: "GoDoIt Application",
      key: "godoit-application",
      images: [naplozz, naplozz],
    },
    {
      label: "GoDoIt Application",
      key: "godoit-application",
      images: [naplozz],
    },
  ];

  const [selectedPortfolioItem, setSelectedPortfolioItem] = useState(0);

  const controls = useAnimationControls();

  function handlePortfolioClick(index: number) {
    controls.start({
      opacity: 0,
    });

    setTimeout(() => {
      setSelectedPortfolioItem(index);
      controls.start({
        opacity: 1,
      });
    }, 300);
  }

  return (
    <Box my="40" py="20" bgColor="gray.100">
      <Heading color="primary.600" fontSize="40px" px="50">
        Our Portfolio
      </Heading>
      <HStack
        align="start"
        paddingLeft={{
          md: "50px",
          xs: "0px",
        }}
        spacing={10}
      >
        <VStack
          alignItems="flex-start"
          display={{
            base: "none",
            md: "flex",
          }}
          minWidth="300px"
        >
          {portfolioItems.map((item, i) => (
            <HStack
              key={item.key}
              _hover={{
                backgroundColor: "secondary.100",
              }}
              cursor="pointer"
              p="3"
              borderRadius="full"
              role="group"
              onClick={() => {
                handlePortfolioClick(i);
              }}
            >
              <TriangleUpIcon
                color="gray.500"
                style={{
                  transform: "rotate(90deg)",
                }}
                _groupHover={{
                  color: "secondary.600",
                }}
              />
              <Text
                fontWeight="medium"
                color="gray.500"
                _groupHover={{
                  color: "secondary.600",
                }}
              >
                {item.label}
              </Text>
            </HStack>
          ))}
        </VStack>

        <VStack
          w="100%"
          overflowX="hidden"
          pl={{
            base: "50",
            md: "0px",
          }}
        >
          <motion.div animate={controls} style={{width: "100%"}}>
            <HStack
              overflowX="scroll"
              className="no-scrollbar"
              align="flex-start"
              w="full"
            >
              {portfolioItems?.[selectedPortfolioItem]?.images?.map(
                (image, i) => (
                  <Box key={i} maxWidth="500px" maxH="300px">
                    <Box py="30px">
                      <Image rounded="md" src={image} />
                    </Box>
                  </Box>
                )
              )}
            </HStack>
          </motion.div>
          <VStack pb="5" maxW="100%" alignItems="flex-start">
            <Text>
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam
            </Text>
          </VStack>
          <HStack w="100%" paddingRight="50">
            <Image src={ScrollLeftSVG} w="60px" />
            <Image src={ScrollRightSVG} w="60px" />
            <Divider borderBottomWidth="5px" borderColor="border.primary" />
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}
