import { Box, Divider, Heading, HStack, Image } from "@chakra-ui/react";
import hybridApp from "../../../images/hybridapp.svg";
import logoDesign from "../../../images/logo design.svg";
import uiuxdesign from "../../../images/uiux.svg";
import webDevelopment from "../../../images/webdevelopment.svg";
import wordpress from "../../../images/wordpress.svg";
import ExpertiseItem from "../components/expertise-item";
import ScrollLeftSVG from "./../../../images/scroll-left.png";
import ScrollRightSVG from "./../../../images/scroll-right.svg";
export default function OurExperties() {
  return (
    <Box my="40">
      <Heading color="primary.600" fontSize="40px" px="50">
        Our Expertise
      </Heading>
      <HStack
        overflowX="scroll"
        className="no-scrollbar"
        marginTop="20px"
        px="50"
      >
        {[
          {
            image: webDevelopment,
            title: "Web Development",
            description:
              "Lorem ipsum dolor sit amet.Cum temporibus exercitationem",
          },
          {
            image: uiuxdesign,
            title: "UI/UX Design",
            description:
              "Lorem ipsum dolor sit amet.Cum temporibus exercitationem",
          },
          {
            image: hybridApp,
            title: "Hybrid App",
            description:
              "Lorem ipsum dolor sit amet.Cum temporibus exercitationem",
          },
          {
            image: wordpress,
            title: "Wordpress",
            description:
              "Lorem ipsum dolor sit amet.Cum temporibus exercitationem",
          },
          {
            image: logoDesign,
            title: "Logo Deisgn",
            description:
              "Lorem ipsum dolor sit amet.Cum temporibus exercitationem",
          },
        ].map((value) => (
          <ExpertiseItem
            image={value.image}
            title={value.title}
            description={value.description}
          />
        ))}
      </HStack>
      <HStack my="20px" px="50">
        <Image src={ScrollLeftSVG} w="60px" />

        <Image src={ScrollRightSVG} w="60px" />

        <Divider borderBottomWidth="5px" borderColor="border.primary" />
      </HStack>
    </Box>
  );
}
