import {
  Box,
  Center,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion, useAnimationControls } from "framer-motion";
import { useState } from "react";
import quoteIcon from "../../../images/Icon awesome-quote-left.svg";
import customerIcon from "../../../images/user1.jpeg";

export default function Clients() {
  const testimonials = [
    {
      photoURL: "https://via.placeholder.com/150",
      name: "John Doe",
      testimonial:
        "1lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. 1lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    },
    {
      photoURL: "https://via.placeholder.com/150",
      name: "John Doe",
      testimonial:
        "2lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    },
    {
      photoURL: "https://via.placeholder.com/150",
      name: "John Do12e",
      testimonial:
        "3lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
    },
  ];

  const [selectedTestomonial, setSelectedTestomonial] = useState(0);

  const controls = useAnimationControls();

  function handleTestimonialClick(index: number) {
    controls.start({
      opacity: 0,
    });

    setTimeout(() => {
      setSelectedTestomonial(index);
      controls.start({
        opacity: 1,
      });
    }, 300);
  }

  return (
    <Center my="40">
      <Stack
        direction={{
          xs: "column-reverse",
          lg: "row",
        }}
        alignItems="center"
        spacing="100"
      >
        <Box boxSize="450px" borderWidth={2} borderRadius="xl">
          <motion.div
            animate={controls}
            style={{
              width: "100%",
              height: "90%",
            }}
          >
            <VStack p="5">
              <HStack justify="space-between" w="100%">
                <Image src={quoteIcon} boxSize="70px" />
                <Image rounded="full" src={customerIcon} boxSize="120px" />
              </HStack>
              <Text textAlign="justify" w="full" h="100%" noOfLines={10}>
                {testimonials?.[selectedTestomonial]?.testimonial}
              </Text>
            </VStack>
          </motion.div>

          <HStack justify="center">
            {testimonials.map((testimonial, index) => (
              <Box
                boxSize={3}
                backgroundColor={
                  index === selectedTestomonial ? "secondary.500" : "gray.400"
                }
                borderRadius="full"
                _hover={{
                  backgroundColor: "secondary.500",
                }}
                cursor="pointer"
                onClick={() => handleTestimonialClick(index)}
              />
            ))}
          </HStack>
        </Box>

        <VStack maxW="600px" alignItems="flex-start">
          <Heading color="primary.600">What Our Clients Have to Say</Heading>
          <Text>
            lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam
          </Text>
        </VStack>
      </Stack>
    </Center>
  );
}
